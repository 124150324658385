<template>
	<div class="task-item" bindlongpress="showMask">
		<div class="task-cover-con">
			<img v-lazy="task.goods_pic" class="task-cover">
			<div class="mask-message">
				<div class="inconformity-tip" v-if="task.xhs_bm_status==1">不符合条件</div>
			</div>
		</div>
		<div class="task-message">
			<div class="task-title">
				<img class="channel-icon" v-if="task.publish_channel&&task.publish_channel!=0"
					:src="channels[task.publish_channel].icon" mode="aspectFit"></img>
				<div class="title">{{task.goods_name}}</div>
			</div>
			<task-tag :task_require="task.task_require" :task_type="task.task_type"
				:task_require_sub="task.task_require_sub" :xhs_prom_type="task.xhs_prom_type">
			</task-tag>
			<!-- <div class="task-price-con">
				<div class="task-price">价值 ￥{{task.goods_price / 100}}</div>
				<div class="fans-require">粉丝要求 {{task.fans_num==0||!task.fans_num?"无":task.fans_num}}</div>
			</div> -->
			<div class="task-bottom">
				<div class="apply-num">
					<div>已报名</div>
					<text class="bm_num">{{task.bm_num}}</text>
				</div>
				<div class="apply-time" @click.stop="showMask">
					<div class="format_time">{{task.format_time}}</div>
					<i class="iconfont icon-more"></i>
				</div>
			</div>
		</div>
		<div :class="['goods-mask',mask_show?'mask-show':'']" @click.stop="hideMask">
			<div class="mask-title">
				<img class="kulian" src="https://img.jimeimadou.com/common/resource/normal_icon/kulian.png"
					mode="aspectFit"></img>
				<div>不感兴趣</div>
			</div>
			<div class="mask-text">选择后将不会再展示此商家的通告</div>
			<div class="mask-btn-con">
				<div class="mask-btn-item" @click.stop="hideMask">否</div>
				<div class="mask-btn-item sure" @click.stop="hideTaskItem">是
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			task: {
				type: Object, //显示弹框
				default: {}
			},
			mask_show:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				channels: this.channels
			}
		},
		methods: {
			showMask() {
				this.$emit('showMask')
			},
			hideMask() {
				this.$emit('hideMask')
			},
			hideTaskItem() {
				this.$emit('hideTaskItem')
			}
		}
	}
</script>

<style lang="scss" scoped>
.task-item{
	border-radius: 12px;
	overflow: hidden;
	background-color: #fff;
	position: relative;
}
.task-cover{
	height: 342px;
	width: 100%;
	display: block;
	border-radius: 12px 12px 0 0;
}
.task-cover-con{
	position: relative;
}
.mask-message{
	position: absolute;
	bottom: 0;
	width: 100%;
}
.goods-price-con{
	display: flex;
	justify-content: flex-end;
	padding: 10px;
}
.goods-price {
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	color: #FFFFFF;
	padding: 0 14px;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.2);
}
.inconformity-tip{
	background-color: rgba(0,0,0,0.4);
	color: #fff;
	font-size: 24px;
	text-align: center;
	line-height: 48px;
}
.task-message{
	padding: 15px;
}
.task-title{
	display: flex;
	align-items: center;
	line-height: 40px;
}
.task-title .title{
	flex: 1;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.channel-icon {
	width: 34px;
	height: 34px;
	display: block;
	margin-right: 8px;
	vertical-align: middle;
}
.task-price-con{
	display: flex;
	justify-content: space-between;
	line-height: 36px;
	margin-top: 15px;
	font-size: 22px;
}
.task-price{
	color:#FF6685;	
	/* text-overflow: ellipsis; */
	overflow: hidden;
	white-space: nowrap;
}
.fans-require{
	color: #aaa;
	/* text-overflow: ellipsis; */
	overflow: hidden;
	white-space: nowrap;
}
.task-bottom{
	display: flex;
	justify-content: space-between;
	line-height: 36px;
	font-size: 22px;
	color: #aaa;
	margin-top: 20px;
}
.apply-num,.apply-time{
	display: flex;
	align-items: center;
}
.bm_num{
	padding-left: 8px;
	color: #333;
}
.icon-more{
	font-size: 20px;
}

.load-tip {
	text-align: center;
	font-size: 26px;
	padding: 30px 0 50px;
	color: #8A8E99;
}

.goods-mask {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 12px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 30px;
	padding: 0 35px;
	transition: 0.2s;
	opacity: 0;
	visibility: hidden;
}

.mask-show {
	opacity: 1;
	visibility: visible;
}

.mask-title {
	line-height: 70px;
	font-size: 36px;
	font-weight: 700;
	display: flex;
	align-items: center;
}

.kulian {
	width: 50px;
	height: 50px;
	display: block;
	margin-right: 12px;
}

.mask-text {
	line-height: 40px;
	margin-bottom: 80px;
	font-size: 26px;
	color: rgba(255, 255, 255, 0.8);
}

.mask-btn-con {
	display: flex;
	justify-content: space-between;
}

.mask-btn-item {
	width: 120px;
	text-align: center;
	line-height: 60px;
	border-radius: 30px;
	color: #aaa;
	background-color: #fff;
}

.mask-btn-item.sure {
	background-color: #FF6685;
	color: #fff;
}
</style>
