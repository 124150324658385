<template>
    <div>
        <div class="top-container" ref="topContainer">
            <div class="require-container">
                <div class="task-require">
                    <div :class="['require-item',select_require==item.type?'require-select':'']" v-for="(item,index) in requireList" :key="index" @click="chooseRequire(item.type)">
                        {{item.name}}
                    </div>
                </div>
                <div class="screen-con" @click="showScreenModel">
                    <div>筛选</div>
                    <i class="iconfont icon-shaixuan"></i>
                </div>
            </div>
            <div class="type-container">
                <div class="task-type">
                    <div :class="['type-item',select_task_type==item.type?'cate-item-checked':'']" v-for="(item,index) in taskTypeList" :key="index" @click="chooseTaskType(item.type)">
                        {{item.name}}
                    </div>
                </div>
                <div :class="['category-btn',cate_screen_show?'no-radius':'']" @click.stop="showCateScreen">
                    <div>
                        <template v-if="select_cates_count==0">商品类目</template>
                        <template v-else>已选类目 ( {{select_cates_count}} )</template>
                    </div>
                    <i :class="['cate-icon','iconfont',cate_screen_show?'icon-arrow-up-bold':'icon-arrow-down-bold']"></i>
                    <div v-if="cate_screen_show" class="category-btn-bottom"></div>
                </div>
                <i :class="['mode-icon','iconfont',listMode==1?'icon-biaoge':'icon-liebiao1']" @click="changeListMode"></i>
            </div>
            <div v-if="cate_screen_show" class="cate-screen-container">
                <div class="cate-wrapper">
                    <div class="cate-item-wrapper" v-for="(item,index) in categoryList" :key="index">
                        <div :class="['cate-item',item.checked?'cate-item-checked':'']" @click="selectCategory(index)">
                            {{item.cate_name}}
                            <img v-if="item.checked" class="cate-check-icon" src="https://img.jimeimadou.com/common/resource/normal_icon/selected.png" mode="aspectFit"></img>
                        </div>
                    </div>
                </div>
                <div class="cate-screen-btn-con">
                    <div class="cate-screen-btn cate-screen-reset" @click="cate_screen_reset">重置</div>
                    <div class="cate-screen-btn cate-screen-confirm" @click="cate_screen_confirm">确认</div>
                </div>
            </div>
        </div>
        <div :class="['cate-mask-container',cate_screen_show?'cate-mask-show':'']" @click.stop="showCateScreen"></div>
        <div :style="'height: '+top_height+'px;'"></div>
        <div class="scroll-container" :style="'height: calc(100vh - '+(top_height+100)+')px;'">
            <scroll-y class="scroll" @loadMore="loadmore" ref="iscroll">
                <empty v-if="isNull" title="暂无通告"></empty>
                <template v-else>
                    <div class="task-container" v-if="listMode==1">
                        <div class="task-item-wrapper" v-for="(item,index) in taskList" :key="index" @click="toTaskDetail(item.id,index)">
                            <task-item-two :task="item" :mask_show="mask_index==index" @showMask="showMask(index)" @hideMask="hideMask(index)" @hideTaskItem="hideTaskItem(item.id,index)"></task-item-two>
                        </div>
                    </div>
                    <div class="task-container" v-if="listMode==2">
                        <div class="task-card-wrapper" v-for="(item,index) in taskList" :key="index" @click="toTaskDetail(item.id,index)">
                            <task-item-one :task="item" :mask_show="mask_index==index" @showMask="showMask(index)" @hideMask="hideMask(index)" @hideTaskItem="hideTaskItem(item.id,index)"></task-item-one>
                        </div>
                    </div>
                    <div v-if="noLogin" class="load-tip">登录后可查看更多~</div>
                    <div v-else class="load-tip">努力加载中</div>
                </template>
            </scroll-y>
        </div>

        <div class="screen-model-mask" v-if="modelName=='screenModel'" @click="hideModel()">
            <div class="screen-model" @click.stop="preventEvent">
                <div class="screen-container">
                    <div class="screen-option">
                        <div class="screen-title">价格</div>
                        <div class="input-con">
                            <input class="input-item" v-model="price_left" placeholder="最低价" />
                            <input class="input-item" v-model="price_right" placeholder="最高价" />
                        </div>
                    </div>
                    <div class="screen-option">
                        <div class="screen-title">年龄</div>
                        <div class="input-con">
                            <input class="input-item" v-model="age_left" placeholder="最小年龄" />
                            <input class="input-item" v-model="age_right" placeholder="最大年龄" />
                        </div>
                    </div>
                    <div class="screen-option">
                        <div class="screen-title">种草类型</div>
                        <div class="channel-con">
                            <div :class="['channel-item',select_zc_type == item.type?'channel-sel':'']" v-for="(item,index) in zcTypeList" :key="index" @click="chooseZhongcaoType(item.type)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                    <div class="screen-option">
                        <div class="screen-title">种草发布渠道</div>
                        <div class="channel-con">
                            <div :class="['channel-item',current_channel==item.type?'channel-sel':'']" v-for="(item,index) in channels" :key="index" @click="chooseChannel(item.type)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>

                    <div class="filter-con">
                        <div class="filter-title">过滤已报名</div>
                        <div style="position: relative;">
                            <van-switch v-model="invite_filter" active-color="#FF6685" @change="handle_filter" class="filter_switch" />
                            <div v-if="noLogin" class="disabled-mask" @click="toLogin"></div>
                        </div>
                    </div>
                </div>
                <div class="screen-btn-con">
                    <div class="screen-btn gray-btn" @click="reset">重置</div>
                    <div class="screen-btn black-btn" @click="confirm">确认</div>
                </div>
            </div>
        </div>

        <div class="auth-model-mask" v-if="modelName=='loginModel'">
            <div class="auth-con" @click.stop="preventEvent">
                <div class="auth-title">授权登录</div>
                <div class="model-content">授权登录后查看更多通告</div>
                <div class="auth-btn-con">
                    <div class="auth-btn-yes" @click="wxLogin_new">授权登录</div>
                    <div class="auth-btn-no" @click="hideModel">再看看</div>
                </div>
            </div>
        </div>

        <img class="totop-btn" src="https://img.jimeimadou.com/common/icon/top.png" v-if="page>2" @click="scrollToTop"></img>
    </div>
</template>

<script>
import scrollY from "@/components/app-scroll-y.vue";
import taskItemOne from "@/components/task-item-one.vue";
import taskItemTwo from "@/components/task-item-two.vue";
export default {
    components: {
        scrollY,
        taskItemTwo,
        taskItemOne,
    },
    data() {
        return {
            categoryList: [],
            requireList: [{
            	name: "全部",
            	type: 0
            }, {
            	name: "小红书",
            	type: 4
            }, {
            	name: "逛逛",
            	type: 5
            }, {
            	name: "抖音",
            	type: 6
            }, {
            	name: "京东",
            	type: 7
            }, {
            	name: "快手",
            	type: 8
            }, {
            	name: "微博",
            	type: 9
            }, {
            	name: "B站",
            	type: 10
            }],
            zcTypeList: [
                {
                    name: "口播介绍",
                    type: 1,
                },
                {
                    name: "开箱测评",
                    type: 2,
                },
                {
                    name: "时尚穿搭",
                    type: 3,
                },
            ],
            taskTypeList: [
                {
                    name: "赠拍",
                    type: 1,
                },
                {
                    name: "寄拍",
                    type: 2,
                },
            ],
            select_require: 0,
            select_zc_type: 0,
            select_task_type: 0,
            select_cates: [],
            select_cates_count: 0,

            navbar_height: 0,
            top_height: 0,
            sex: [
                {
                    text: "不限",
                    type: 0,
                },
                {
                    text: "男",
                    type: 1,
                },
                {
                    text: "女",
                    type: 2,
                },
            ],
            //价格
            price_left: "",
            price_right: "",
            //年龄
            age_left: "",
            age_right: "",
            scrollLeft: 0,
            invite_filter: false,
            isShowModel: false,
            modelName: "",
            popup_position: "right",
            channels: this.channels,
            xhsType: this.xhsType,
            current_channel: 0,
            noLogin: false,
            scrollTop: 0,
            mask_index: -1,

            sentData: {}, //列表请求参数
            requestUrl: "",
            page_size: 10,
            page: 1,
            stopLoad: false,
            isNull: false,
            taskList: [],
            fresh_status: false,
            listMode: 1,
            cate_screen_show: false,
            hasClick: false,
        };
    },
    created() {
        this.invite_filter = localStorage.getItem("has_filter") || false;
        this.getTaskList();
        this.getCates();
    },
    mounted() {
        // console.log(this.$refs.topContainer.offsetHeight)
        this.top_height = this.$refs.topContainer.offsetHeight;
    },
    methods: {
        changeListMode() {
            this.scrollToTop();
            this.list_refresh();
            if (this.listMode == 1) {
                this.listMode = 2;
            } else {
                this.listMode = 1;
            }
        },
        list_refresh() {
            this.initData();
            this.getTaskList();
            this.$forceUpdate();
        },
        initData() {
            this.page = 1;
            this.taskList = [];
            this.stopLoad = false;
            this.isNull = false;
            this.mask_index = -1;
            this.scrollToTop();
        },
        scrollToTop() {
            console.log("回到顶部", this.$refs);
            this.$refs.iscroll.scrollToTop();
        },
        getTaskList() {
            if (this.stopLoad) {
                return;
            }
            let sentData = {
                page: this.page++,
                page_size: this.page_size,
                task_require: this.select_require,
                task_type: this.select_task_type,
                task_require_sub: this.select_zc_type,
                price_left: this.price_left,
                price_right: this.price_right,
                age_left: this.age_left,
                age_right: this.age_right,
                is_contact: this.invite_filter ? 1 : 0,
                cate_id: this.select_cates.join(","),
                publish_channel: this.current_channel,
            };
            this.sentData = sentData;
            let params = this.$addParams(sentData);
            // console.log(params);
            this.$axios
                .post(
                    process.env.VUE_APP_URL + "/mobi/task/listv3",
                    this.$qs.stringify(params),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == "y") {
                        let result = JSON.parse(res.data.data);
                        console.log("通告列表====", result);

                        if (result.list.length < this.page_size) {
                            this.stopLoad = true;
                            if (result.list.length == 0 && this.page == 2) {
                                this.isNull = true;
                            }
                        }
                        if (result.list.length != 0) {
                            this.taskList.push(...result.list);
                        }
                    }
                });
        },
        loadmore() {
            if (this.noLogin) {
                this.modelName = "loginModel";
                return;
            }
            this.getTaskList();
        },
        showMask(index) {
			return
            console.log(index);
            this.mask_index = index;
        },
        hideMask() {
            this.mask_index = -1;
        },
		toWeApp(){
			this.$router.push({
				name: 'shareCode'
			})
		},
        showCateScreen() {
            console.log("切换cate_screen_show", this.cate_screen_show);
            this.categoryList.forEach((item) => {
                item.checked = this.select_cates.includes(item.id);
            });
            this.cate_screen_show = !this.cate_screen_show;
            this.select_cates_count = this.select_cates.length;
        },
        cate_screen_reset() {
            this.categoryList.forEach((item) => {
                item.checked = false;
            });
            this.select_cates_count = 0;
        },
        cate_screen_confirm() {
            let select_cates = [];
            this.categoryList.forEach((item) => {
                if (item.checked) {
                    select_cates.push(item.id);
                }
            });
            this.cate_screen_show = false;
            this.select_cates = select_cates;
            this.list_refresh();
        },
        selectCategory(e) {
            let index = e.currentTarget.dataset.index;
            this.categoryList[index].checked =
                !this.categoryList[index].checked;
            if (this.categoryList[index].checked) {
                this.select_cates_count++;
            } else {
                this.select_cates_count--;
            }
        },
        selectCategory(index) {
            this.categoryList[index].checked =
                !this.categoryList[index].checked;
            if (this.categoryList[index].checked) {
                this.select_cates_count++;
            } else {
                this.select_cates_count--;
            }
        },
        getCates() {
            this.categoryList = [
                {
                    id: 1,
                    cate_name: "女装",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate01.png",
                },
                {
                    id: 2,
                    cate_name: "男装",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate02.png",
                },
                {
                    id: 13,
                    cate_name: "童装",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate05.png",
                },
                {
                    id: 3,
                    cate_name: "鞋包配饰",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate03.png",
                },
                {
                    id: 4,
                    cate_name: "美妆个护",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate04.png",
                },
                {
                    id: 5,
                    cate_name: "母婴",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate13.png",
                },
                {
                    id: 6,
                    cate_name: "食品",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate06.png",
                },
                {
                    id: 7,
                    cate_name: "家居家装",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate07.png",
                },
                {
                    id: 8,
                    cate_name: "数码家电",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate08.png",
                },
                {
                    id: 9,
                    cate_name: "运动户外",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate09.png",
                },
                {
                    id: 10,
                    cate_name: "保健",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate10.png",
                },
                {
                    id: 11,
                    cate_name: "内衣",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate11.png",
                },
                {
                    id: 12,
                    cate_name: "其它",
                    icon: "https://img.jimeimadou.com/common/cate_icon/cate12.png",
                },
            ];
        },
        toLogin() {
            this.$router.push({
                name: "login",
            });
        },
        //隐藏通告
        hideTaskItem(id, index) {
            let sentData = {
                access_token: localStorage.getItem("access_token"),
                task_id: id,
                act_val: 1,
            };
            let params = this.$addParams(sentData);
            // console.log(params);
            this.$axios
                .post(
                    process.env.VUE_APP_URL + "/mobi/mote/handleboringtask",
                    this.$qs.stringify(params),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == "y") {
                        this.taskList.splice(index, 1);
                        this.mask_index = -1;
                        this.$toast("操作成功");
                        if (this.taskList.length < this.page_size) {
                            this.getTaskList();
                        }
                    } else {
                        if (res.data.error == "nologin") {
                            this.toLogin();
                        } else {
                            this.$toast(res.data.error);
                        }
                    }
                });
        },
        toSearch() {
            console.log("去搜索");
        },
        //查看通告详情
        toTaskDetail(id, index) {
            console.log("通告详情");
            let ids = this.taskList.map((item) => item.id);
            this.$router.push({
                name: "goodsdetail",
                query: {
                    id,
                    index,
                    params: JSON.stringify(this.sentData),
                    ids: JSON.stringify(ids),
                },
            });
        },
        showCateModel() {
            this.popup_position = "right";
            this.modelName = "cateModel";
            this.isShowModel = true;
        },
        showScreenModel() {
            this.popup_position = "center";
            this.modelName = "screenModel";
            this.isShowModel = true;
        },
        hideModel() {
            this.isShowModel = false;
            this.modelName = "";
        },
        chooseChannel(type) {
            if (type == this.current_channel) {
                type = "";
            }
            this.current_channel = type;
        },
        chooseRequire(type) {
            this.select_require = type;
            this.list_refresh();
        },
        chooseTaskType(type) {
            if (type == this.select_task_type) {
                type = 0;
            }
            this.select_task_type = type;
            this.list_refresh();
        },
        //选择种草类型
        chooseZhongcaoType(type) {
            if (type == this.select_zc_type) {
                type = 0;
            }
            this.select_zc_type = type;
        },
        confirm() {
            this.list_refresh();
            this.hideModel();
        },
        preventEvent() {
            return;
        },
        reset() {
            this.price_left = "";
            this.price_right = "";
            this.age_right = "";
            this.age_left = "";
            this.current_channel = 0;
            this.select_zc_type = 0;
            this.list_refresh();
        },
        handle_filter(status) {
            // console.log(status)
            localStorage.setItem("has_filter", status);
            this.list_refresh();
        },
    },
};
</script>

<style lang="scss" scoped>
.top-container {
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 200;
}

.require-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    line-height: 100px;
    height: 100px;
    z-index: 200;
}

.task-require {
    overflow-x: auto;
	white-space: nowrap;
}
.task-require::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.require-item {
	display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
    font-weight: 700;
    color: #222;
    font-size: 30px;
}

.require-item:last-child {
    margin-right: 0;
}

.require-select {
    color: #ff6685;
}

.screen-con {
    display: flex;
    font-size: 28px;
	flex-wrap: nowrap;
	white-space: nowrap;
	padding-left: 20px;
}

.icon-shaixuan {
    margin-left: 5px;
    font-size: 24px;
    position: relative;
    top: 2px;
}

.type-container {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 20px;
    align-items: center;
    z-index: 200;
}

.task-type {
    display: flex;
}

.type-item {
    width: 120px;
    line-height: 46px;
    height: 50px;
    text-align: center;
    border-radius: 25px;
    background-color: #f5f6fa;
    margin-right: 30px;
    color: #666;
    font-size: 26px;
    border: 2px solid #f5f6fa;
    box-sizing: border-box;
}

.type-item:last-child {
    margin-right: 0;
}

.category-container {
    display: flex;
    align-items: center;
}

.category-btn {
    display: flex;
    justify-content: center;
    font-size: 26px;
    line-height: 50px;
    color: #666;
    padding: 0 30px 0 40px;
    height: 50px;
    border-radius: 25px;
    background-color: #f5f6fa;
    position: relative;
}

.no-radius {
    border-radius: 25px 25px 0 0;
}

.category-btn-bottom {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    height: 20px;
    background-color: #f5f6fa;
}

.category-btn .cate-icon {
    font-size: 20px;
    margin-left: 3px;
}

.cate-screen-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f5f6fa;
    z-index: 120;
}

.cate-screen-show {
    transform: translateY(100%);
    opacity: 1;
    visibility: visible;
}

.cate-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
}

.cate-item-wrapper {
    width: 25%;
}

.cate-item {
    background-color: #fff;
    border-radius: 12px;
    line-height: 60px;
    font-size: 24px;
    color: #888;
    text-align: center;
    border: 2px solid #fff;
    position: relative;
    overflow: hidden;
    margin: 8px;
}

.cate-check-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    display: block;
}

.cate-item-checked {
    background-color: #ffebef;
    color: #ff6685;
    border-color: #ff6685;
}

.cate-screen-btn-con {
    display: flex;
}

.cate-screen-btn {
    flex: 1;
    text-align: center;
    font-size: 30px;
    line-height: 90px;
    color: #666;
    background-color: #fff;
    letter-spacing: 5px;
}

.cate-screen-confirm {
    background-color: #ff6685;
    color: #fff;
}

.cate-mask-container {
    background-color: rgba(0, 0, 0, 0);
    width: 100vw;
    height: calc(100vh - 100px);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 90;
    visibility: hidden;
    transition: 0.3s;
}

.cate-mask-show {
    background-color: rgba(0, 0, 0, 0.5);
    visibility: visible;
}
.mode-icon {
    font-size: 30px;
    color: #666;
}

.filter-con {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.filter-title {
    font-size: 30px;
    color: #222;
    font-weight: 700;
}

.disabled-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
}

.filter_switch {
    transform: scale(0.8);
}
.filter_switch::after,
.filter_switch::before {
    content: none;
}

.scroll-container {
    position: relative;
    height: calc(100vh - 270px);
}

.scroll {
    width: 100%;
    height: 100%;
}

.task-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 14px 0;
}

.task-item-wrapper {
    width: 50%;
    padding: 0 10px 20px;
}

.task-card-wrapper {
    width: 100%;
    margin: 0 2px 16px;
}

.zhongcao_type_con {
    display: flex;
    justify-content: space-around;
}

.zhongcao-item {
    width: 200px;
    height: 50px;
    line-height: 50px;
    background-color: #fff;
    border-radius: 25px;
    text-align: center;
    color: #8a8e99;
}

.totop-btn {
    position: fixed;
    right: 24px;
    bottom: 160px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.screen-model-mask {
    position: fixed;
    width: 100vw;
    top: 0;
    bottom: 100px;
    left: 0;
    z-index: 300;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
}

.screen-model {
    width: 550px;
    height: 100%;
    position: relative;
    background-color: #fff;
    padding: 20px 30px;
}

.screen-option {
    padding-bottom: 20px;
}

.screen-title {
    text-align: left;
    line-height: 60px;
    font-size: 30px;
    font-weight: 700;
}

.input-con {
    display: flex;
    justify-content: space-between;
}

.input-item {
    width: 220px;
    height: 60px;
    background-color: #f5f5f5;
    border-radius: 30px;
    text-align: center;
}

.channel-con {
    display: flex;
    flex-wrap: wrap;
}

.channel-item {
    width: 150px;
    text-align: center;
    line-height: 60px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin: 10px 20px 10px 0;
    color: #888;
    font-size: 26px;
    border: 2px solid #f5f5f5;
}

.channel-item.channel-sel {
    background-color: #ffebef;
    color: #ff6685;
    border-color: #ff6685;
}

.channel-item:nth-child(3n) {
    margin-right: 0;
}

.screen-btn-con {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-around;
}

.screen-btn {
    width: 220px;
    line-height: 70px;
    text-align: center;
    border-radius: 35px;
}

.gray-btn {
    color: #000;
    background-color: #f5f5f5;
}

.black-btn {
    color: #fff;
    background-color: #000;
}

.load-tip {
    text-align: center;
    color: #aaa;
    font-size: 26px;
    padding: 20px 0 50px;
}
.auth-model-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-con {
    width: 560px;
    border-radius: 20px;
    overflow: hidden;
}

.auth-title {
    background-image: url(https://img.jimeimadou.com/common/resource/index_icon/bj.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    line-height: 100px;
    color: #fff;
    font-weight: 700;
    font-size: 40px;
}

.model-content {
    color: #666;
    font-size: 30px;
    padding: 60px 0;
}

.auth-btn-con {
    padding: 0 30px 20px;
    line-height: 80px;
    color: #888;
    font-size: 28px;
}

.auth-btn-yes {
    background-color: #ff6685;
    color: #fff;
    border-radius: 10px;
}
</style>
