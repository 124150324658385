<template>
	<div class="task-card">
		<div class="cover-container">
			<img v-lazy="task.goods_pic" class="task-cover">
			<div class="inconformity-tip" v-if="task.xhs_bm_status==1">不符合条件</div>
		</div>
		<div class="task-message">
			<div class="task-message-up">
				<div class="task-title">
					<img class="channel-icon" v-if="task.publish_channel&&task.publish_channel!=0"
						:src="channels[task.publish_channel].icon" mode="aspectFit"></img>
					{{task.goods_name}}
				</div>
				<task-tag :task_require="task.task_require" :task_type="task.task_type"
					:task_require_sub="task.task_require_sub" :xhs_prom_type="task.xhs_prom_type">
				</task-tag>
			</div>
			<div class="task-message-down">
				<div class="task-fans-con" v-if="task.task_require==4">
					<div class="task-fans">粉丝要求 {{task.fans_txt}}</div>
				</div>
				<div class="task-price-con">
					<div class="task-price">
						<template v-if="task.task_require!=1">预算 {{task.budget_txt}}</template>
						<template v-else-if="task.task_type==1">价值 ￥{{task.goods_price/100}}</template>
					</div>
					<div class="apply-num">已报名 {{task.bm_num}}</div>
				</div>
				<div class="task-demand-con">
					<div class="connect-type">{{connectType[task.connect_type]||"平台联系"}}</div>
					<div class="format-time-con" @click.stop="showMask">
						<div class="format-time">{{task.format_time}}</div>
					</div>
				</div>
			</div>
		</div>
		<div :class="['task-mask-container',mask_show?'task-mask-show':'']" @click.stop="hideMask">
			<div class="mask-left">
				<div class="mask-title">
					<img class="kulian" src="https://img.jimeimadou.com/common/resource/normal_icon/kulian.png"
						mode="aspectFit"></img>
					<div>不感兴趣</div>
				</div>
				<div class="mask-text">选择后将不会再展示此商家的通告</div>
			</div>
			<div class="mask-right">
				<div class="mask-btn mask-btn-no" @click.stop="hideMask">否</div>
				<div class="mask-btn mask-btn-yes" @click.stop="hideTaskItem">是</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			task: {
				type: Object, //显示弹框
				default: {}
			},
			mask_show:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				channels: this.channels,
				connectType:{
					1:"微信联系",
					2:"平台联系",
					3:"qq联系"
				}
			}
		},
		methods: {
			showMask() {
				this.$emit('showMask')
			},
			hideMask() {
				this.$emit('hideMask')
			},
			hideTaskItem() {
				this.$emit('hideTaskItem')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.task-card {
		display: flex;
		justify-content: space-between;
		position: relative;
		padding: 15px;
		border-radius: 15px;
		background-color: #fff;
		overflow: hidden;
	}

	.cover-container {
		position: relative;
		margin-right: 15px;
	}

	.inconformity-tip {
		position: absolute;
		width: 100%;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.4);
		color: #fff;
		font-size: 24px;
		text-align: center;
		line-height: 48px;
		border-radius: 0 0 10px 10px;
	}

	.task-cover {
		width: 240px;
		height: 240px;
		border-radius: 10px;
		overflow: hidden;
	}

	.task-message {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.task-title {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		font-size: 30px;
		font-weight: 700;
		color: #222;
		line-height: 40px;
		margin-bottom: 5px;
	}

	.channel-icon {
		width: 34px;
		height: 34px;
		display: inline-block;
		position: relative;
		top: 8px;
	}

	.task-demand {
		font-size: 22px;
		line-height: 30px;
		margin-top: 6px;
		color: #888;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
	}

	.task-message-down {
		color: #aaa;
		line-height: 34px;
		font-size: 22px;
	}

	.task-price-con {
		display: flex;
		justify-content: space-between;
	}

	.task-price {
		color: #FE346E;
	}

	.task-demand-con {
		display: flex;
		justify-content: space-between;
	}

	.format-time-con {
		display: flex;
		align-items: center;
	}

	.task-mask-container {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.8);
		border-radius: 15px;
		color: #fff;
		display: flex;
		justify-content: space-between;
		padding: 0 60px;
		transition: 0.2s;
		opacity: 0;
		visibility: hidden;
	}

	.task-mask-show {
		opacity: 1;
		visibility: visible;
	}

	.mask-left {
		width: 340px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.mask-title {
		line-height: 70px;
		font-size: 36px;
		font-weight: 700;
		display: flex;
		align-items: center;
	}

	.kulian {
		width: 50px;
		height: 50px;
		display: block;
		margin-right: 12px;
	}

	.mask-text {
		line-height: 40px;
		font-size: 26px;
		color: rgba(255, 255, 255, 0.8);
		margin-bottom: 20px;
	}

	.mask-right {
		display: flex;
		align-items: center;
	}

	.mask-btn {
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: 30px;
		color: #888;
		border-radius: 50%;
		background-color: #fff;
	}

	.mask-btn-yes {
		background-color: #FF6685;
		color: #fff;
		margin-left: 30px;
	}
</style>
